import React, { useEffect, useRef, useState } from "react";
import useUserData from "@/hooks/useUserData";
import { useMutation } from "wagmi";
import { ErrorDto } from "@/types/error.dto";
import { getPosts, PostResponse } from '@/queries/feed/posts/getPosts.query';
import { Post } from "../feed/Post";
import { useRouter, useSearchParams } from "next/navigation";
import { Button } from "@/components/atoms";
import SharePostModal from "../feed/SharePostModal";

const EarnCdoSidebar = () => {
  const router = useRouter();
  const getUser = useUserData();
  const [closeVisible, setCloseVisible] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [posts, setPosts] = useState<PostResponse | null>(null);
  const bottomBoundaryRef = useRef<HTMLDivElement>(null);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [showSharePostModal, setShowSharePostModal] = useState<boolean>(false);
  const [chosenPostId, setChosenPostId] = useState<string>("");

  const getPostsMut = useMutation(({ page, limit, usersIds, id }: { page: number, limit?: number, usersIds?: string[], id?: string[] }) => getPosts(page, limit, usersIds, id), {
    retry: false,
    onSuccess: (data) => {
      // console.log(data)
      if(data.error){
        let timeoutId;
        timeoutId = setTimeout(() => {
          setPage(page-1);
        }, 500);
        clearTimeout(timeoutId);
      } else if(data.data.length > 0) {
        setPosts((prevFeed) => ({
          data: [...(prevFeed?.data || []), ...data.data],
          meta: data.meta,
        }));
        setTotalPages(data.meta.pageCount)
      }
    },
    onError: (e: ErrorDto) => {
      console.error("Error: " + e)
    },
  });
  
  const fetchFeed = async (page: number) => {
    getPostsMut.mutate({ page: page, id: ['c6b90d2f-da41-4051-bbc1-f08c0191d74d'] })
  };

  const fetchNextPage = () => {
    setPage((prevPage) => prevPage + 1);
    fetchFeed(page + 1)
  };

  useEffect(() => {
    posts && setTotalPages(posts?.meta.pageCount)
  }, [posts])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          posts && setTotalPages(posts.meta.pageCount);
          if(!page || (page < totalPages)) {
            fetchNextPage();
          }
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );
    if (bottomBoundaryRef.current) {
      observer.observe(bottomBoundaryRef.current);
    }
    return () => observer.disconnect();
  }, [posts]);

  function handleShare(postId: string) {
    setChosenPostId(postId);
    setShowSharePostModal(true);
  }

  function closeSidebar() {
    router.push('social-graph');
  }

  return (
    <>
      <div className={`fixed top-0 right-0 z-50 h-screen backdrop-blur-sm bg-gray-400 bg-opacity-50 w-full ${isVisible && 'visible' }`}>
        <div className="flex flex-row">
          <div onClick={closeSidebar} className={`${closeVisible && 'sm:w-full'}`}/>
          <div className={`bg-white h-screen border-l shadow ${isVisible && "visible" } rounded-l-lg w-full p-4 pb-12 overflow-x-auto scrollbar-none w-full lg:w-1/2`}>
            <div className="flex flex-col text-[14px]">
              <div className="flex flex-row justify-between">
                <span className="uppercase text-indigo-600 font-semibold text-[12px]">Step 2</span>
                <button onClick={closeSidebar}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 4L4 12" stroke="#6B7280" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 4L12 12" stroke="#6B7280" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg></button>
              </div>
              <span className="text-gray-800 font-medium text-[16px]">
                Earn CDO Tokens
              </span>
              <span className="text-gray-600 ">Share content and earn 25 CDO tokens each time a friend joins Candao via your shared posts, verifies their account, and invites a friend to do the same.</span>
              <div className="flex flex-col gap-2 py-4">
              {
                posts?.data.map((post, index) => {
                  return (
                    <>
                      <Post key={index} post={post} postId={post.id}/>
                      <Button onClick={() => handleShare(post.id)} variant="primary" className="mb-4">Share & Earn 25 CDO</Button>
                    </>
                  )
                })
              }
              </div>
              <SharePostModal isModalOpen={showSharePostModal} hideModal={() => setShowSharePostModal(false)} feedId={chosenPostId} />
              <div ref={bottomBoundaryRef} />
            </div>
          </div>
        </div>
      </div>    
    </>
  );
};

export default EarnCdoSidebar;
