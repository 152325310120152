import { Box } from "@/components/molecules";
import { Button } from "@/components/atoms";
import Link from "next/link";
import React, { FC } from "react";
import { useRouter } from "next/navigation";
import useUserData from "@/hooks/useUserData";

export const EarnCdoStep2 = () => {
    const router = useRouter();
    const userData = useUserData();

    const step1Status = userData?.profile.avatarUrl && userData.profile.avatarUrl !== ""
    const step2Status = userData?.profile.socialAccountsData && userData.profile.socialAccountsData.filter((account: { isVerified: boolean; }) => account.isVerified).length >= 3

    if(step1Status && step2Status) {
        return (
            <Box className="p-4 flex flex-col gap-1">
                <span className="text-[12px] font-semibold uppercase text-indigo-600">step 2</span>
                <span className="text-[16px] font-semibold text-gray-800">Earn CDO Tokens</span>
                <span className="text-[14px] text-gray-600">Share content and earn 25 CDO tokens each time a friend joins Candao via your shared posts, verifies their account, and invites a friend to do the same. <button onClick={() => {router.push("/earn-cdo")}} className="text-indigo-600">Learn more</button></span>
                <Button className="mt-2 w-full flex flex-row gap-2 items-center" variant="primary" size="base" onClick={() => router.push("/social-graph?step=2")}>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.6665 13.3334L13.3332 8.66675M8.6665 13.3334V9.33341C8.6665 9.1566 8.73674 8.98703 8.86177 8.86201C8.98679 8.73699 9.15636 8.66675 9.33317 8.66675H13.3332M8.6665 13.3334H3.99984C3.64622 13.3334 3.30708 13.1929 3.05703 12.9429C2.80698 12.6928 2.6665 12.3537 2.6665 12.0001V4.00008C2.6665 3.64646 2.80698 3.30732 3.05703 3.05727C3.30708 2.80722 3.64622 2.66675 3.99984 2.66675H11.9998C12.3535 2.66675 12.6926 2.80722 12.9426 3.05727C13.1927 3.30732 13.3332 3.64646 13.3332 4.00008V8.66675" stroke="white" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    Share 2 Earn
                </Button>
            </Box>
        );
    }
};
